<template>
  <ButtonBase class="back slide slide-left label">
    <template #before>
      <Icon name="arrow-regular-left-mobile" />
    </template>
    <slot />
    <template #after>
      <Icon name="arrow-regular-left-mobile" />
    </template>
  </ButtonBase>
</template>

<style lang="scss" scoped>
.button.back {
  --pv: 0;
  --ph: 0;
  --color-border: transparent;
  --color-border-hover: transparent;

  --amount: 26px;

  justify-self: start;

  // padding: 0 30px !important;
  :deep(svg) {
    width: 12px;
    height: 12px;
  }
}
</style>
